import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RLink } from "react-router-dom";

export function Footer() {
  return (

    <Stack
      component="footer"
      direction="column"
      spacing={3}
      alignItems={'center'}
      sx={{
        py: 2,
        px: 2,
        background: '#00000022',
        backdropFilter: 'blur(5px)',
        boxShadow: '0px -1px 10px 0px #00000044',
      }}>

      <Container sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent={'space-evenly'}>

          <Grid item xs={3} sm={2} md={2}>
            <Stack
              direction="column"
              alignItems={'flex-start'}
              sx={{
                color: 'text.secondary',
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'space-between',
                // alignItems: 'flex-start',
                // flexGrow: 1,
                // flexBasis: '30%',
              }}>
              <Typography variant="subtitle1" mb={1} fontWeight={'bold'}>
                Links
              </Typography>
              <Typography variant="subtitle2">
                <Link
                  as={RLink}
                  to="/">
                  Features
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link
                  as={RLink}
                  to="/">
                  FAQ
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={5} sm={3} md={3}>
            <Stack
              direction="column"
              alignItems={'flex-start'}
              sx={{
                color: 'text.secondary',
              }}>
              <Typography variant="subtitle1" mb={1} fontWeight={'bold'}>
                Legal
              </Typography>
              <Typography variant="subtitle2">
                <Link
                  as={RLink}
                  to="/terms">
                  Terms of Service
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link
                  as={RLink}
                  to="/privacy">
                  Privacy Policy
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={3} sm={2} md={3}>
            <Stack
              direction="column"
              alignItems={'flex-start'}
              sx={{
                color: 'text.secondary',
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'space-between',
                // alignItems: 'flex-start',
                // flexGrow: 1,
                // flexBasis: '30%',
              }}>
              <Typography variant="subtitle1" mb={1} fontWeight={'bold'}>
                Social
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  Facebook
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  Telegram
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  Twitter
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5} md={4} xl={3}>
            <Stack
              direction="column"
              // spacing={{ xs: 1, sm: 2, md: 4 }}
              sx={{
                alignItems: { xs: 'center', sm: 'flex-start' },
              }}>
              <Typography variant="subtitle1" mb={1} fontWeight={'bold'}>
                Contact Us
              </Typography>
              <Typography variant="subtitle2">
                Dubai Silicon Oasis, Dubai, UAE
              </Typography>
              {/* <Typography variant="subtitle2">
                <Link
                  color="inherit"
                  href="tel:+971 58 505 7901"
                  target="_blank"
                  rel="noopener noreferrer">
                  +971 58 505 7901
                </Link>
              </Typography> */}
              <Typography variant="subtitle2">
                <Link
                  color="inherit"
                  href="mailto:contact@echoai.in"
                  target="_blank"
                  rel="noopener noreferrer">
                  contact@echoai.in
                </Link>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Typography variant="subtitle1" sx={{
        width: '100%',
        pt: 2,
        borderTop: '1px solid #00000022',
      }}>
        ECHO AI SOLUTIONS - FZE, 2024
      </Typography>
    </Stack>
  )
}

export default Footer;
